import styled, { css } from 'styled-components';
import {
  BackgroundCover,
  FlexColCenter,
  FlexRowCenter,
} from '@styles/global-styles';

export const HomeWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fefefe;
  color: rgba(0, 0, 0, 0.75);
  position: relative;
  display: flex;
  .instructTomb {
    position: fixed;
    top: 80px;
    left: 20px;
    font-size: 24px;
    border-radius: 50%;
    z-index: 9;
    i {
      color: #fff;
      box-shadow: 0px 0px 6px 0.5px #0000004d;
      border-radius: 50%;
    }
    span {
      position: absolute;
      left: calc(100% + 12px);
      display: block;
      width: 200px;
      color: #fff;
      font-size: 14px;
      text-shadow: #000 5px 0 10px;
      top: 50%;
      transform: translate(0, -50%);
    }
  }

  .mainContent {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .headerTomb {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    padding: 0 80px;
    display: flex;
    background: rgba(194, 27, 27, 1);
    align-items: center;
    z-index: 9;
    color: #fff;
    .--txt {
      flex: 0 0 auto;
    }
    .--top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
    .--title {
      display: flex;
      align-items: center;
      gap: 12px;
      span {
        font-size: 20px;
        font-weight: 700;
        text-transform: uppercase;
        display: block;
        flex: 0 0 auto;
        width: auto;
      }
      img {
        height: 40px;
        object-fit: contain;
      }
    }
    .--name {
      font-size: 14px;
      margin-top: 4px;
    }
    .--search {
      display: flex;
      align-items: center;
      gap: 12px;
      input {
        height: 32px;
        background: transparent;
        color: #fff;
        border: none;
        padding: 4px 0;
        border-bottom: 1px solid #fff;
        &::placeholder {
          color: #fff;
        }
        &:focus,
        &:focus-visible {
          outline: none;
        }
      }
      i {
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 auto;
        border-radius: 50%;
        color: rgba(194, 27, 27, 1);
        background: #fff;
        cursor: pointer;
      }
    }
  }
  .infoUser {
    position: fixed;
    right: 40px;
    top: 60%;
    background: rgba(194, 27, 27, 1);
    border-radius: 50%;
    width: 32px;
    height: 32px;
    border: 1px solid #fff;
    color: #fff;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  @media (max-width: 769px) {
    .instructTomb {
      top: 120px;
      &.top {
        top: 80px;
      }
    }
    .infoUser {
      right: 20px;
    }
    .headerTomb {
      height: auto;
      padding: 12px 15px;
      display: flex;
      flex-flow: column;
      gap: 8px;
      align-items: end;
      .--top {
        align-items: center;
      }
      .--title {
        span {
          font-size: 16px;
          margin-bottom: 4px;
        }
      }
      .--search {
        position: relative;
        transform: none;
        top: unset;
        left: unset;
        width: 100%;
        input {
          font-size: 12px;
          flex-grow: 1;
        }
      }
      .--name {
        display: flex;
        flex-flow: column;
        align-items: end;
        font-size: 14px;
      }
    }
  }
`;

export const HeaderWrapper = styled.div`
  position: absolute;
  width: 100%;
  z-index: 10;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0));
  padding: 0.25rem 0.75rem 1.5rem;
  color: #fff;
  ${FlexColCenter};
  .title {
    &-main {
      font-weight: 500;
      margin-top: 0.7rem;
      margin-bottom: 0.7rem;
      font-size: 0.9rem;
      text-transform: uppercase;
      letter-spacing: 0.06rem;
      text-shadow: 0 1px 6px rgb(0 0 0 / 0.1);
    }
    &-extra {
      ${FlexRowCenter};
      font-size: 15px;
      width: 100%;
      &__scene {
        font-size: 0.8rem;
        font-weight: 300;
        margin: 0 1.6rem;
        margin-top: -0.25px;
        text-shadow: 0 1px 6px rgb(0 0 0 / 0.35);
        text-align: center;
        /* font-family: 'Dancing Script', cursive; */
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 100%;
      }
      &__icon {
        font-size: 1.1rem;
        cursor: pointer;
        transition: all 0.05;
        i {
          width: 1.9rem;
          height: 1.2rem;
          border: 1px solid rgb(255 255 255 / 0.6);
          /* background: rgb(0 0 0 / 0.5); */
          backdrop-filter: blur(10px);
          /* border-radius: 6px; */
          display: flex;
          align-items: center;
          justify-content: center;
          transition: var(--transition-smooth);
          @media (hover: hover) and (pointer: fine) {
            &:hover {
              /* transform: scale(1.2, 1); */
              background: #fff;
              color: #212121;
            }
          }
        }
        &.prevIcon {
        }
        &.nextIcon {
        }
        @media (hover: hover) and (pointer: fine) {
          &:hover {
            text-shadow:
              0 0 20px #fff,
              0 0 30px #ff4da6,
              0 0 40px #ff4da6,
              0 0 50px #ff4da6,
              0 0 60px #ff4da6,
              0 0 70px #ff4da6,
              0 0 80px #ff4da6;
          }
        }
      }
    }
  }
`;

export const ChangeSceneWrapper = styled.div<{ bgUrl: string; show: boolean }>`
  ${BackgroundCover};
  position: absolute;
  width: 220px;
  height: 100%;
  z-index: 10;
  cursor: pointer;
  transition: transform 0.4s;
  top: 0;
  .navigation {
    position: absolute;
    width: 50px;
    height: 50px;
    top: 50%;
    margin-top: -25px;
    left: -25px;
    cursor: pointer;
    background: white;
    transform: rotate(45deg);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    z-index: 11;
    i {
      position: absolute;
      font-size: 24px;
    }
  }
  .title {
    position: absolute;
    left: 3rem;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
    z-index: 15;
    .subTitle {
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 1.5px;
      opacity: 0.75;
    }
    .mainTitle {
      /* font-family: 'Dancing Script', cursive; */
      font-size: 1.25rem;
    }
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black;
    opacity: 0.75;
  }
`;

export const PrevSceneWrapper = styled(ChangeSceneWrapper)`
  box-shadow: 12px 0px 24px rgba(0, 0, 0, 0.15);
  border-right: 1px solid rgb(255 255 255 / 0.5);
  left: 0;
  transform: translateX(-100%);
  transition: var(--transition-smooth);
  .navigation {
    right: -25px;
    left: auto;
    i {
      ${props =>
        props.show
          ? css`
              transform: translate(-50%, -50%) rotate(-45deg);
              left: 50%;
              top: 50%;
            `
          : css`
              transform: rotate(135deg);
              right: 10px;
              left: auto;
              top: 3px;
            `}
    }
  }
  .title {
    right: 3rem;
    text-align: right;
    overflow: hidden;
  }
  ${props =>
    props.show &&
    css`
      transform: none;
    `}

  @media(max-width: 768px) {
    transform: translateX(-100%) !important;
    .navigation i {
      transform: rotate(-45deg) !important;
      right: 10px !important;
      top: 3px !important;
      left: unset !important;
    }
  }
`;

export const NextSceneWrapper = styled(ChangeSceneWrapper)`
  box-shadow: -12px 0px 24px rgba(0, 0, 0, 0.15);
  border-left: 1px solid rgb(255 255 255 / 0.5);
  right: 0;
  transform: translateX(100%);
  transition: var(--transition-smooth);
  .navigation {
    i {
      ${props =>
        props.show
          ? css`
              transform: translate(-50%, -50%) rotate(-45deg);
              left: 50%;
              top: 50%;
            `
          : css`
              transform: rotate(135deg);
              left: 10px;
              right: auto;
              top: 20px;
            `}
    }
  }
  ${props =>
    props.show &&
    css`
      transform: none;
    `}

  @media(max-width: 768px) {
    transform: translateX(100%) !important;
    .navigation i {
      transform: rotate(-45deg) !important;
      left: 10px !important;
      top: 24px !important;
    }
  }
`;

export const TourWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;
